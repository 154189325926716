import axios from 'axios';

export function getToken() {
    return window.firebase.auth().currentUser.getIdToken(true)
}

export function doGet(url, params) {
    return new Promise(resolve => {
        getToken()
            .then((token) => {
                const headers = {
                    Authorization: `Bearer ${token}`
                }
                //const baseUrl = "http://localhost:5000/mytransport-246817/us-central1/api"
                //const baseUrl = "https://api.mbility.eu/api"
                const baseUrl = process.env.REACT_APP_BASE_URL + '/api';
                axios.get(baseUrl + url, {
                    headers: headers,
                    params: params
                })
                    .then(response => {
                        if (response.status === 200) {
                            const res = response.data
                            if (res.success) {
                                resolve(res.data)
                            } else {
                                resolve(res.message)
                            }
                        }
                    })
            })
    })
}

export function doPost(url, data) {
    return new Promise((resolve, reject) => {
        getToken()
            .then((token) => {
                const headers = {
                    Authorization: `Bearer ${token}`
                }
                //const baseUrl = "http://localhost:5000/mytransport-246817/us-central1/api"
                //const baseUrl = "https://api.mbility.eu/api"
                const baseUrl = process.env.REACT_APP_BASE_URL + '/api';
                axios.post(baseUrl + url, data, { headers: headers })
                    .then(response => {
                        if (response.status === 200) {
                            const res = response.data
                            if (res.success) {
                                resolve(res.data)
                            } else {
                                reject(res.message)
                            }
                        }
                    })
            })
    })
}